import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Divider } from '@/components/ui/Divider';
import { Link } from 'gatsby';
import { SmallMap } from '@/components/maps/SmallMap';
import { useBanner } from '@/hooks/useBanner';
import { Banner } from '@/components/banner';

export default function TariffeEOrari() {
  const pageTitle = 'Tariffe e Orari';
  const components = useBanner('tariffe-e-orari');

  const TopBanner = () => (
    <StaticImage
      src="../images/banners/parcheggi-di-intercambio-e-camper.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const BorderWrapper = ({
    hasText,
    children,
    borderSize = '2.618rem',
  }: {
    hasText?: boolean;
    children: React.ReactNode;
    borderSize?: string;
  }) => {
    const borderStyle = '2px solid #9d9fa0';
    const borderTopBottom = `${borderSize} solid #9d9fa0`;
    const style = {
      minHeight: '400px',
      borderTop: borderTopBottom,
      borderBottom: borderTopBottom,
      borderLeft: borderStyle,
      borderRight: borderStyle,
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    return (
      <div className="position-relative">
        <div className={hasText ? 'left-map' : ''} style={style}>
          {children}
        </div>
      </div>
    );
  };

  const Left = () => (
    <BorderWrapper hasText={true}>
      <SmallMap />
    </BorderWrapper>
  );

  const Right = () => (
    <div className="mt-4 mt-lg-0">
      <BorderWrapper borderSize={'13px'}>
        <StaticImage
          src="../images/other/Legenda.jpg"
          quality={100}
          alt="mappa"
        />
      </BorderWrapper>
    </div>
  );

  const Center = () => (
    <div>
      <div className="row mb-5 text-center">
        <div className="col-12 col-md-3">
          <a
            href="/files/tariffe-e-orari/PIANO TARIFFARIO_STALLI BLU_Agg. 2025.02.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-3">
              Tariffe strisce blu
            </button>
          </a>
        </div>
        <div className="col-12 col-md-3">
          <a
            href="/files/tariffe-e-orari/NUOVO_ TARIFFE PARCHEGGI INTERSCAMBIO.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-3">
              Tariffe parcheggi a sbarra
            </button>
          </a>
        </div>
        <div className="col-12 col-md-3">
          <a
            href="/files/tariffe-e-orari/NUOVO_ TARIFFE PARCHEGGI INTERSCAMBIO.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-3">
              Tariffe parcheggi interscambio
            </button>
          </a>
        </div>
        <div className="col-12 col-md-3">
          <a
            href="/files/tariffe-e-orari/PIANO TARIFFARIO_STALLI GIALLI_Agg. 2025.02.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-3">
              Elenco stalli gialli
            </button>
          </a>
        </div>
      </div>
      <div className="mb-5">
        <h2 className="text-center h2 text-primary font-rubik">ZONA FIERA</h2>
        <div className="mx-auto" style={{ maxWidth: '960px' }}>
          <p className="mt-5 mb-5 text-secondary">
            Le tariffe per la sosta in occasione degli eventi fieristici sono
            stabilite in:
            <br />
            <br />- € 10,00/giorno in occasione delle manifestazioni “Vicenza
            Oro”,
            <br />- € 6,00/giorno in occasione di tutti gli altri eventi.
            <br />
            <br /> Le tariffe per la sosta in occasione delle aperture serali
            della discoteca “Villa Bonin” sono stabilite in:
            <br />
            <br />- € 2,00/giorno per l'area denominata P4;
            <br />- € 5,00/ giorno per l'area denominata P7
            <br />
            <br /> Tra le aree di cui sopra, quelle denominate P4 e P7 sono
            adibite a parcheggi a pagamento, tra l'altro, in occasione delle
            aperture nei giorni di apertura serale della discoteca “Villa Bonin”
            dalle ore 20:00 alle ore 6:00.
            <br />
          </p>
        </div>
        <div>
          <Banner components={components} />
        </div>
      </div>
      <div>
        <h2 className="text-center h2 text-primary font-rubik">
          AREE DI SOSTA CAMPER PARK CRICOLI E PARK STADIO
        </h2>
        <p
          className="mx-auto mt-5 mb-5 text-secondary"
          style={{ maxWidth: '960px' }}
        >
          Presso i parcheggi Stadio e Cricoli, è attivo un servizio di sosta
          camper. Il servizio mette a disposizione dei proprietari dei camper un
          impianto per lo scarico delle acque nere ed un rubinetto con acqua
          potabile per il rifornimento dei serbatoi d'acqua dei camper.
          <br /> Dai parcheggi di interscambio sono attive due servizi
          bus-navetta, denominate “Centrobus Cricoli” e “Centrobus Stadio”, che
          fanno parte del servizio di trasporto pubblico locale. Negli orari di
          attivazione del Trasporto Pubblico Locale (dalle ore 6:00 alle ore
          22:00), l'accesso ai parcheggi di interscambio Stadio e Cricoli
          avviene con emissione del biglietto di andata e ritorno per il
          servizio di Trasporto Pubblico Locale di € 2,40 (linea 10 per il
          parcheggio Stadio e linea 30 per il parcheggio Cricoli), mentre la
          sosta è gratuita.
          <br />
          Per quanto riguarda il servizio camper presso i parcheggi Stadio e
          Cricoli, lo stesso è fruibile compreso nel biglietto “Centrobus A/R
          Camper”, nell'abbonamento solo sosta camper ed in abbonamento con
          utilizzi a scalare (carnet), come riportato in
          <a
            href="/files/tariffe-e-orari/ALLEGATO_ TARIFFE PARCHEGGI INTERSCAMBIO.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-3 mt-3">
              Tariffe parcheggi interscambio
            </button>
          </a>
        </p>
      </div>
      <Divider size={3} />
      <div style={{ maxWidth: '960px', margin: '0 auto' }}>
        <h2 className="text-center h2 text-primary font-rubik">
          MOBILITÀ ELETTRICA:
          <br />
          agevolazioni alla sosta e alla circolazione
        </h2>
        <p className="mx-auto mt-5 mb-5 text-secondary">
          Con Delibera di Giunta Comunale n. 90 del 24 maggio 2016 (e successive
          modifiche ed integrazioni) sono state stabilite le modalità per
          usufruire della sosta con tariffa ridotta all'interno del Comune di
          Vicenza sugli stalli blu a pagamento ed all'interno dei parcheggi ad
          accesso controllato (a sbarra), per i veicoli ibridi (motore termico
          combinato con motore elettrico)
          <br />
          <br />
          <span className="fw-bold">SOSTA SUGLI STALLI BLU</span>
          <br />
          Per sostare con tariffa ridotta sugli stalli blu è necessario attivare
          il pagamento della sosta tramite GPS app o EasyPark. Tramite l'app
          basterà inserire la targa dei veicoli ibridi e selezionare la zona
          tariffaria di riferimento
          <br />
          <br />
          <span className="fw-bold">SOSTA NEI PARCHEGGI A SBARRA</span>
          <br />
          Per accedere ai parcheggi a sbarra gestiti da GPS Global Parking
          Solutions S.p.A. è necessario ottenere la GPS KEY (a partire dal 1
          gennaio 2023), recandosi con la documentazione del veicolo ibrido
          presso i front office di GPS Vicenza (
          <Link to="/contatti">
            <span className="text-underline cursor-pointer">vedi orari</span>
          </Link>
          ).
          <br />
          <br />
          La GPS KEY, che deve essere utilizzata per entrare ed uscire dal
          parcheggio, consente il pagamento, presso le casse automatiche dei
          parcheggi a sbarra, di usufruire di uno sconto sulla normale tariffa
          di sosta in vigore. Tariffa oraria 50 centesimi all'ora
          indipendentemente dalla zona e sconto del 50% sulla tariffa in vigore
          per i parcheggi a sbarra.
        </p>
        <Link to="/modulistica">
          <button className="btn btn-primary mb-3">
            Moduli richiesta GPS KEY
          </button>
        </Link>
      </div>
      <Divider size={3} />
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<TopBanner />} />
      <div className="container mt-5 mb-5">
        <div className="mb-3 text-center">
          <h2 className="h3 text-primary font-rubik">
            Indicazione planimetrica delle zone tariffarie
          </h2>
        </div>
        <div className="row gx-5">
          <div className="col-12 col-lg-6">
            <Left />
          </div>
          <div className="col-12 col-lg-6">
            <Right />
          </div>
        </div>
        <Divider size={5} />
        <Center />
      </div>
    </Layout>
  );
}
